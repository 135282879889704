<template>
    <v-sheet elevation="8">

       <v-row no-gutters>
           <v-spacer/>
           <v-col md="3">
                <v-text-field class="pr-5" v-model="filterSearch" prepend-icon="mdi-magnify" label="Search" single-line clearable></v-text-field>
           </v-col>
       </v-row>

       <v-row no-gutters>
           <v-col md="12">
           <v-data-table :headers="headers" :items="data" item-key="cmsurn" disable-sort height="65vh" :search="filterSearch">

<template v-slot:item="row">
   <tr @click="contactClicked(row.item)">
        <td style="width:50%; text-align:left; padding-left: 10%;">{{getContactDescription(row.item)}}</td>
       <!-- <td style="width:25%; text-align:center">{{row.item.digits}}</td>
       <td style="width:25%; text-align:center">{{row.item.forenames}}</td>
       <td style="width:25%; text-align:center">{{row.item.surname}}</td> -->
       <!-- <td style="width:25%; text-align:center">{{row.item.credit}}</td>  -->
       <!-- <td style="width:10%">
       <v-btn class="mx-2" small icon @click="editUser(row.item.userID)">
           <v-icon dark>mdi-account-edit</v-icon>
       </v-btn>
       <v-btn class="mx-2" small icon @click="deleteUser(row.item.userID)">
           <v-icon dark>mdi-delete</v-icon>
       </v-btn>
       </td> -->
   </tr>

</template>

</v-data-table>
</v-col>
       </v-row>
  

</v-sheet>

</template>
 
<script>
 
 export default {

   props:{ 
       data: Array,
   },

   computed:{
        
   },   
 
   data: () => ({

       filterSearch: null,

       headers:[
           {
               text: 'Numbers',
               align: 'center',
               sortable: false,
               value: 'digits'
           },


       ]
     //
   }),
   methods:{
        getContactDescription(contact){
            const contactName = (contact.forenames + " " + contact.surname).trim()
            return `${contact.digits} known as ${contactName} to ${contact.pin}`
        },

       contactClicked(contact){
           this.$emit("contactSelected", contact)
       }
   },  
   mounted(){
   }
 };

</script>
 