<template>
    <div>
        <v-row class="pt-5" no-gutters>
            <v-col md="12">
                <v-tabs class="pb-5" background-color="#F2F2F2" center-active v-model="selectedTab">
                    <v-tab key="1">Summary</v-tab>
                    <v-tab key="2">Numbers</v-tab>
                    <v-tab key="3">Conversations</v-tab>
                </v-tabs>

                <v-tabs-items v-model="selectedTab" v-if="accountProfile != null">
                    <v-tab-item>
                        
                        <AccountSummary :account="accountProfile" v-on:creditUpdated="getAccountProfile"/>
                
                    </v-tab-item>

                    <v-tab-item>
                        
                        <AccountNumbers v-if="accountProfile.contacts"  :data="accountProfile.contacts"/>
                        <div v-else>This account has no contacts</div>
                
                    </v-tab-item>

                    <v-tab-item>
                        <AccountConversation  ref="accountConversation" :account="accountProfile" :preDefinedfilters="selectedFilers"/>
                    </v-tab-item>

                </v-tabs-items>

            </v-col>
        </v-row>

        <!-- <v-row no-gutters>
            <v-col md="12">

                <v-card elevation="8" >

                    <v-card-title>{{ account.name }}</v-card-title>
                    <v-card-subtitle>{{ account.cmsurn }}</v-card-subtitle>
                    
                    <v-row no-gutters v-if="accountProfile != null">
                        <v-card-subtitle>Credit: £{{ accountProfile.credit.balance }}</v-card-subtitle>
                        <v-card-subtitle>Purchased Messages: {{ accountProfile.credit.boughtMessages }}</v-card-subtitle>
                        <v-card-subtitle>Free Messages: {{ accountProfile.credit.freeTexts }}</v-card-subtitle>
                        <v-spacer/>
                        <div class="pr-5 pt-4">
                            <span class="dot mr-2" :style="{'background-color': accountProfile.lastActive == null ? 'red': 'green'}"></span>
                            <span>{{accountProfile.lastActive == null ? "Offline" : "Online"}}</span>
                        </div>
                    </v-row>

                </v-card>
                
            </v-col>

        </v-row> -->

    </div>
</template>

  
<script>

import AccountSummary from "@/views/AccountDetails/AccountSummary.vue"
import AccountNumbers from "@/views/AccountDetails/AccountNumbers.vue"
import AccountConversation from "@/views/AccountDetails/AccountConversation.vue"
  
  export default {

    props:{
        accountId: String,
        meta:Object,

    },

    components:{
        AccountSummary,
        AccountNumbers,
        AccountConversation
    },
  
    data: () => ({
        accountProfile: null,
        accountProfileLoaded: false,

        selectedTab: null,

        selectedFilers: null

    }),

    methods:{
        getAccountProfile(){
            return new Promise((resolve,reject) => {
                this.$utapService.accounts.getAccountProfile(this.$store.getters.getSiteId, this.accountId).then(profile => {
                this.accountProfileLoaded = true
                this.accountProfile = profile
                resolve()
            })
            .catch(error => {
                reject(error)
            })
        })
        }
    },

    created(){

        this.getAccountProfile().then(() => {
            try{
                const meta = this.$route.query.meta
                if(meta){
                    if(meta.view == "conversation"){
                        this.selectedFilers = meta.filters
                        this.selectedTab = 2
                    }
                }
            }
            catch(error){
                console.log("no meta: " + error)
            }
        })


        }
        
    };

</script>


<style scoped>
.dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
</style>
  