<template>
    
    <v-card elevation="4" >
        <v-container class="pt-10"  style="background-color: transparent;">

            <v-row md>
                <v-col md="3">

                    <v-autocomplete v-model="selectedAccount" 
                    :items="accounts"
                    :item-text="accountNameToDisplay"
                    label="Accounts"
                    @change="changeAccount"
                    return-object>

                    </v-autocomplete>

                </v-col>

                <v-col md="3">
                    
                    <v-autocomplete v-model="selectedContact" 
                    :items="availableContacts"
                    :item-text="contactNameToDisplay"
                    label="Contacts"
                    return-object

                    ></v-autocomplete>


                </v-col>

            </v-row>

        <v-row >

           

            <v-col md="3">
                <v-menu
                v-model="fromDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="computedFromDateFormatted"
                            label="From date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="fromDate"
                    @input="fromDateMenu = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>    
            <v-col md="3">
                <v-menu
                v-model="toDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="computedToDateFormatted"
                            label="To date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="toDate"
                    @input="toDateMenu = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>  
            <v-col md="3">
                <v-text-field solo label="Word/Phrase" v-model="wordPhrase"></v-text-field> 
            </v-col>  
        </v-row>


    </v-container>

    <v-card-actions>
        <v-spacer/>
        <v-btn @click="clearFilter()">Reset</v-btn>
        <v-btn :disabled="disableSearch" @click="searchFilter()"  color="primary">Search</v-btn>
    </v-card-actions>

    </v-card>
    
</template>

<script>
export default {

    props:{
        accounts: Array,
    },


    data(){
        return{
            
            availableContacts: [],

            selectedAccount: null,
            selectedContact: null,

            fromDateMenu: false,
            fromDate: null,

            toDateMenu: false,
            toDate: null,

            wordPhrase: null,

            disableSearch: false
        }
    },

    computed:{
        computedFromDateFormatted () {
            return this.fromDate ? this.moment(this.fromDate).format('dddd, MMMM Do YYYY') : ''
        },

        computedToDateFormatted () {
            return this.toDate ? this.moment(this.toDate).format('dddd, MMMM Do YYYY') : ''
        },

        
    },  

    methods:{

        changeAccount(account){
            this.getContacts()
            this.$emit("accountChanged", account)
        },

        getContacts(){
            const pin = this.selectedAccount.number
            this.$utapService.accounts.getAccountContacts(this.$store.getters.getSiteId, pin).then(contacts => {
                this.availableContacts = contacts
            })
            .catch(error => {
            console.log("Error fetching accounts: " + error)
            })
      },

        clearFilter(){
            this.selectedAccount = null
            this.selectedContact = null
            this.fromDateMenu = false
            this.toDateMenu = false
            this.fromDate = null
            this.toDate = null
            this.wordPhrase = ""
            this.$emit("filtersCleared")
        },

        searchFilter(){
            var searchFilters = {account: this.selectedAccount, contact: this.selectedContact, fromDate: this.fromDate, toDate: this.toDate, wordPhrase: this.wordPhrase}
            this.$emit("filterConfirmed", searchFilters)
        },

        accountNameToDisplay(account){
            return `${account.name} (${account.cmsurn})`
        },

        contactNameToDisplay(contact){
            return `${contact.forenames} ${contact.surname} (${contact.digits})`
            
        },
        

        disableSearchButton(disable){
            this.disableSearch = disable
        },





    },
}
</script>