<template>
	<v-dialog persistent v-model="isVisible" :max-width="styleSettings.width" :style="{ z: styleSettings.z }" @keydown.esc="cancel">
		<v-card>
			<v-toolbar dark color="#1b526d" dense flat>
				<v-toolbar-title class="white--text">Refund Messages</v-toolbar-title>
			</v-toolbar>

			<v-container class="pl-4 pr-1">
  				<v-row no-gutters>
    				<v-col md="2" sm="2" class="mt-3">
						<v-btn fab dark small @click="decrement()" >
      						<v-icon dark>mdi-minus</v-icon>
   						</v-btn>
   					</v-col>
    				<v-col md="8" class="pr-3">
						<v-text-field persistent-hint autofocus style="padding-left: 20px; padding-right:20px" ref="numberSelector" readonly hide-spin-buttons v-model="messageQuantity" type="number" label="Number of messages:" :hint=" 'Total: £' + calculatePrice()"></v-text-field>
   					</v-col>
					<v-col md="2" sm="2" class="mt-3">
						<v-btn fab dark small @click="increment()" >
      						<v-icon dark> mdi-plus</v-icon>
   						</v-btn>
   					</v-col>
  				</v-row> 
			</v-container>
			
			<v-card-actions class="pt-0">
				<v-spacer></v-spacer>
				<v-btn color="#2d7da5" text @click.native="confirm"><b>Refund</b></v-btn>
				<v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
			</v-card-actions>
		</v-card>

		<v-dialog persistent v-model="noCreditDialog" :max-width="styleSettings.width" :style="{ z: styleSettings.z }">
			<v-card>
				<v-toolbar dark color="#1b526d" dense flat>
					<v-toolbar-title class="white--text">Error</v-toolbar-title>
				</v-toolbar>
				<v-card-text class="pa-4">Insufficient PIN credit</v-card-text>
				<v-card-actions class="pt-0">
					<v-btn color="#2d7da5" text @click="noCreditDialog = false"><b>Ok</b></v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-dialog>
</template>

<script>
export default {

    props:{
        pricePerMessage: Number
    },

	data: () => ({
		isVisible: false,
		resolve: null,
		incrementBy: 5,
		messageQuantity: 50,
		maximumMessages: 500,
		minimumMessages: 1,
		noCreditDialog: false,
		styleSettings: {
		color: 'primary',
		width: 350,
		z: 200
		}
	}),

	methods: {

		open(styleSettings) {
			this.isVisible = true
			this.messageQuantity = 50
			this.styleSettings = Object.assign(this.styleSettings, styleSettings == null ? this.styleSettings : styleSettings)
			return new Promise((resolve) => {
				this.resolve = resolve
			})
		},
		increment () {
			if(this.messageQuantity < this.maximumMessages){
				if(this.messageQuantity == 1){
					this.messageQuantity = 5
				}
				else{
					this.messageQuantity = parseInt(this.messageQuantity,10) + this.incrementBy
				}
				
				this.$refs.numberSelector.focus()
			}
      		
    	},
		decrement () {
			if(this.messageQuantity > this.minimumMessages){
				if(this.messageQuantity == 5){
					this.messageQuantity = 1
				}
				else{
					this.messageQuantity = parseInt(this.messageQuantity,10) - this.incrementBy
				}
				
				this.$refs.numberSelector.focus()
			}
			
		},
		calculatePrice(){
			if(this.pricePerMessage == null ) return 0
			const cost = this.messageQuantity * this.pricePerMessage
			return cost.toFixed(2)
		},
		confirm() {
			if(this.messageQuantity >= this.minimumMessages && this.messageQuantity <= this.maximumMessages){
				this.$emit("messagesRefunded", this.messageQuantity)
                this.cancel()
			}
			
		},
		cancel() {
			this.resolve(false)
			this.messageQuantity = this.minimumMessages
			this.isVisible = false
		},
		reset() {
			this.resolve = null,
			this.styleSettings = {color: 'primary', width: 400, z:200}
		}
	}
}
</script>

<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
</style>
