<template>
    <v-container fluid>
        <v-row>
            <v-col md="6"></v-col>
            <v-col md="6">

                <div class="d-flex">
                    <v-text-field v-model="siteFilter" single-line hide-details dense rounded filled placeholder="Search" prepend-inner-icon="mdi-magnify"></v-text-field>
                </div>

                <v-data-table v-if="tenants.length > 0  && !this.loadingTenants" class="mt-5" :items="tenants" :loading="loadingTenants" :headers="tableHeaders" 
                :search="siteFilter" group-by="tenant.description" hide-default-footer @click:row="selectSite">\

                    <template v-slot:[`group.header`]="{ items }">
                        <th colspan="4">
                            <h2>{{ items[0].tenant.description }}</h2>
                        </th>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <div class="d-flex buildRow">
                            {{ item.description }}
                        </div>
                    </template>
                    
                </v-data-table>
                <div v-else class="pt-5 pl-5"> 
                    <span>Unable to load sites</span>
                    <v-btn icon class="ml-2" @click="loadTenants">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </div>
                
            </v-col>
        </v-row>
    </v-container>
    
</template>

<script>

export default{

    async created(){
        this.$store.commit("setSite", {})
        await this.loadTenants()
    },

    data:() => ({

        tableHeaders:[
        { text: 'Description', align: 'left', sortable: true, value: 'description', width: '100%' }
        ],

        siteFilter: "",

        tenants: [],
        sites: [],

        selectedTenant: undefined,

        loadingTenants: false
    }),


    methods:{
        async loadTenants(){
            if(!this.loadingTenants){
                try{
                    this.loadingTenants = true

                    const tenants = await this.$utapService.tenants.get()
                    this.tenants = tenants
                    
                }
                finally{
                    this.loadingTenants = false
                    console.log("Loading tenants: " + this.loadingTenants)
                    console.log("Tenants: " + JSON.stringify(this.tenants))
                }
            }
        },

        async selectSite(site){
            try{
                const siteResponse = await this.$utapService.tenants.getSite(site.key);
                if(siteResponse){
                    this.$store.commit("setSite", site)
                    this.$router.push({ path: `${site.key}/dashboard`})
                }

            }
            catch(error){
                console.error("Unable to validate site")
            }
            
        }
    },     
}
</script>

<style scoped>

.siteRow:hover{
    cursor: pointer;
}

.siteItem:hover{
    background-color: #E1F5FE;
}
.siteItem:hover .siteItemText{
    cursor: pointer;

}

</style>
