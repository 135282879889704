<template>
  <v-app>

    <v-app-bar app color="primary" flat clipped-left>
      <div class="d-flex align-center">
        <v-btn v-if="$store.getters.getSiteId" icon large @click="goHome"><v-icon>mdi-home</v-icon></v-btn>
      </div>
      <div class="d-flex align-center">
        <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="@/assets/logo.png" transition="scale-transition" width="70"/>
      </div>
      
      <v-spacer/>
      <v-btn @click="logout">Logout</v-btn>

    </v-app-bar>
    
    <v-main app class="main">
        <router-view >
        </router-view>
    </v-main>
  </v-app>
</template>

<script>

import "@fontsource/raleway"

export default {
  name: 'App',
 


  data: () => ({
  
  }),



  methods:{

    goHome(){
      this.$router.push("/")
    },

    logout(){
      this.$auth.signOut()
    }

  },

};

</script>

<style>
  .v-application{
    font-size: 1.1rem !important;
    font-family: "Raleway";
  }

  h2{
  font-weight: 300;
  }

  html, body{
    font-family: "Raleway";
  }

  .container{
    background-color: #f2f2f2;
    height: 100%;
  }
</style>

<style scoped>
  .SelectedTile:hover {
    border-radius: 4px;
    background: #455A64
  }

  .SelectedTile-active {
    border-radius: 4px;
    background: rgba(10, 204, 117, 0.19)
  }

</style>
