import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Vuetify from 'vuetify/lib'
import './plugins/compositionapi'
import router from '@/router'
import auth from '@/auth'
import store from '@/store'
import APIService from './services/UTAP-API/APIService'

import config from '../config'
import moment from 'moment'

Vue.prototype.$config = config.utapApi
Vue.use(auth)

auth.useRouter(router)

APIService.useAuth(auth)


//APIService.useToken("eyJhbGciOiJSUzI1NiIsImtpZCI6IjM5OTVCQzkyN0U1NjJGRTY0RDUwQjRGODExNUFGOEI0IiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2NzM1MzYxMTQsImV4cCI6MTY3MzgzNjExNCwiaXNzIjoiaHR0cHM6Ly9pZGVudGl0eS51bmlmeWN1c3RvZGlhbGh1Yi5jb20vYXBpIiwiY2xpZW50X2lkIjoicGljcyIsInN1YiI6Im5pY2ttdXJyYXl2aXNAZ21haWwuY29tIiwiYXV0aF90aW1lIjoxNjczNTM2MTEzLCJpZHAiOiJsb2NhbCIsIlVzZXJJZCI6Im5pY2ttdXJyYXl2aXNAZ21haWwuY29tIiwiU3VybmFtZSI6Ik11cnJheSIsIkZvcmVuYW1lcyI6IkFkbWluR3V5IiwiVGhlbWUiOiJFeHRlcm5hbCIsIkxvY2F0aW9uIjoiIiwiVGVuYW50IjoiRE9WIiwicm9sZSI6IlN0YWZmIiwic2lkIjoiNUE5RTlCMjY0MEM3NDEyNEUzOTBGRjU0NzExQzU4NjMiLCJpYXQiOjE2NzM1MzYxMTQsInNjb3BlIjpbIm9wZW5pZCIsInByb2ZpbGUiLCJhcGkxIl0sImFtciI6WyJwd2QiXX0.g-RmyCaDNq0oWLj9z8fnL4a0N0WlZ22KAQhVUHWffQ_b8UUlBOA35Z-E4yup0fcOcZRigOS-c-iEXsqVlkxQhBbI086ILLAiT-rlZu8WwKD5xtP3ttylRPV3pHbOiqUMFbR81gLU4T6NX36tXxE1_Q1pxk8IMRM1TkKRuCII9FxjIkLVh6rr_dOA07nFxKV7pyGRgU1Wzpt_77N3Etn1bFR5GbTJ4bTtotnms969KIA3ZHJRztp_BQqdbEZeWWwJtBZG12g7J8eJF-gAHtyY9lcuO8ER7DV82JqOlxBKVK_atQ35OJds2OTS-5taXZAubxJjHoVc2u8P8LdzROLRVA")
Vue.prototype.$utapService = APIService
Vue.config.productionTip = false
Vue.prototype.moment = moment

Vue.prototype.formatTime = (time) => {
  if(time){
    return moment(time).format("DD-MM-YYYY hh:mm")
  }
  
}

Vue.use(Vuetify, {
  iconfont: 'mdi'
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
