<template>
    <v-dialog v-model="visible" max-width="400px">
        <v-card>
            <v-card-title>{{ title }}</v-card-title>
            <v-card-subtitle class="mt-1">{{ subtitle }}</v-card-subtitle>

            <v-card-actions>
                <v-spacer/>
                <v-btn @click="visible = false">
                    Cancel
                </v-btn>
                <v-btn @click="confirm" color="green">
                    Confirm
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
    
</template>

<script>
export default {

    props:{
        title: String,
        subtitle: String
    },  

    data: () => ({
        visible: false,
    }),
    
    methods:{

        open(){
            this.visible = true
        },  
        close(){
            this.visible = false
        },


        confirm(){
            this.$emit("confirmed")
            this.close()
        }
    }
}
</script>