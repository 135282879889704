<template>
    <v-container>
        <v-row no-gutters> 

            <v-col md="12">
                <AccountConversationFilter ref="accountConvFilter" :contacts="account.contacts" v-on:filterConfirmed="applyFilter"/>
            </v-col>

        </v-row>

        <v-row v-if="wordFilterMessages != null ">

            <v-spacer />
            <div></div>
            <div class="mr-5 mt-1">{{selectedOccurenceIndex + 1}}/{{wordFilterMessages.length}} word occurences</div>
            <v-btn icon @click="goBackMessageOccurence"><v-icon>mdi-arrow-up</v-icon></v-btn>
            <v-btn icon @click="goNextMessageOccurence"><v-icon>mdi-arrow-down</v-icon></v-btn>
        </v-row>

        <v-row>



            <v-col md="12">

                <v-sheet  class="conversationSheet" elevation="4">

                    <Transcript style="max-height:60vh; overflow:scroll" ref="conversationTranscript" class="px-5" v-if="messages != null && messages.length > 0" :messages="messages"></Transcript>
                    <div v-else class="text-center pt-15" >
                        
                        <div v-if="!searchInProgress"  >No messages available</div>
                        <v-progress-circular class="mt-5" indeterminate color="primary" v-else></v-progress-circular>
       
                    </div>
                    

                </v-sheet>

            </v-col>

        </v-row>

    </v-container>

</template>
  
<script>

import AccountConversationFilter from '@/views/AccountDetails/AccountConversationFilter'
import Transcript from '@/components/Transcript'
  
  export default {

    props:{
      account: Object,
      preDefinedfilters: Object
    },  

    components:{
        AccountConversationFilter,
        Transcript
    },
  
    data: () => ({
        messages: undefined,
        wordFilterMessages: undefined,

        selectedOccurenceIndex: 0,
        
        searchInProgress: false
    }),

    methods:{

        async applyFilter(filters){
            this.$refs.accountConvFilter.disableSearchButton(true)
            
            this.searchInProgress = true
            this.selectedOccurenceIndex = 0
            this.wordFilterMessages = null

            var filterParams = {fromDate: filters.fromDate, toDate: filters.toDate}
            const messages = await this.$utapService.messages.getConversation(this.account.pinNumber, filters.contact.digits,filterParams)
            this.messages = messages.bodies  
            
            if(filters.wordPhrase){
                var paramsWithWordFilter = filterParams
                paramsWithWordFilter.wordOrPhrase = filters.wordPhrase
                const wordFilterMessages = await this.$utapService.messages.getConversation(this.account.pinNumber, filters.contact.digits,paramsWithWordFilter)
                this.wordFilterMessages = wordFilterMessages.bodies
                this.$refs.conversationTranscript.highlightMessages(wordFilterMessages.bodies)
            }
            else{
                if(this.$refs.conversationTranscript)
                    this.$refs.conversationTranscript.clearHighlights()
            }
            this.$refs.accountConvFilter.disableSearchButton(false)
            this.searchInProgress = false

        },

        goNextMessageOccurence(){
            if(this.selectedOccurenceIndex < this.wordFilterMessages.length - 1 || this.wordFilterMessages.length == 1){

                if(this.wordFilterMessages.length > 1){
                    this.selectedOccurenceIndex++
                }
                
                const message = this.wordFilterMessages[this.selectedOccurenceIndex]
                this.$refs.conversationTranscript.scrollToMessage(message)

            }
            
        },
        goBackMessageOccurence(){
            if(this.selectedOccurenceIndex > 0){
                this.selectedOccurenceIndex--
                const message = this.wordFilterMessages[this.selectedOccurenceIndex]
                this.$refs.conversationTranscript.scrollToMessage(message)
            }   
        }
        
    },
    created(){
        
       
    },

    mounted(){
        if(this.preDefinedfilters){
            if(this.preDefinedfilters.account != null){
                this.$refs.accountConvFilter.setFilters(this.preDefinedfilters)
                const filters = this.$refs.accountConvFilter.getFilters()
                this.applyFilter(filters)
            }
        }
        
    }
  };

 

</script>


<style scoped>

.conversationSheet{
    min-height: 50vh;
}

</style>
  