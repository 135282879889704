<template>
    <v-container>

        <v-row>

          <v-col md="12">

            <ConversationFilter ref="messageFilter" v-on:filterConfirmed="onFilterApplied" :accounts="accounts"/>
          </v-col>

        </v-row>

        <v-row>
          <v-col md="12">
            <div style="height:50vh; background-color:white; text-align:center" v-if="searchingForOccurences" >
              <v-progress-circular class="mt-5" indeterminate color="primary" ></v-progress-circular>
            </div>
            <ConversationFilterResult v-else style="height:50vh; overflow:scroll" class="filterResult" :results="messageOccurences" v-on:occurenceSelected="onMessageOccurenceSelected"/>
          </v-col>
          
        </v-row>

    </v-container>

</template>
  
<script>

import ConversationFilter from '@/views/Messages/ConversationFilter'
import ConversationFilterResult from '@/views/Messages/ConversationFilterResult'

  
  export default {

    components:{
      ConversationFilter,
      ConversationFilterResult
    },    
  
    data: () => ({
      accounts: [],
      messageOccurences: [],
      searchingForOccurences: false,
      lastSearchedFilters: null
    }),


    methods:{
      getAccounts(){
        this.$utapService.accounts.getAllAccounts(this.$route.params.site).then(accounts => {
          this.accounts = accounts
        })
        .catch(error => {
          console.log("Error fetching accounts: " + error)
        })
      },

      async onFilterApplied(filter){
        const selectedAccount = filter.account
        const selectedContact = filter.contact
        
        this.$refs.messageFilter.disableSearchButton(true)
        this.searchingForOccurences = true

        var filterParams = {pin: selectedAccount != null ? selectedAccount.number : null, digits: selectedContact != null ? selectedContact.digits : null, fromDate: filter.fromDate, toDate: filter.toDate, wordOrPhrase: filter.wordPhrase}
        this.lastSearchedFilters = filter

        try{
          const occurences = await this.$utapService.messages.getConversationOccurences(this.$store.getters.getSiteId,filterParams)
          this.messageOccurences = occurences
        }
        catch(error){
          console.log("error: " + error)
        }
        finally{
          this.$refs.messageFilter.disableSearchButton(false)
          this.searchingForOccurences = false
        }
        
      },

      onMessageOccurenceSelected(occurence){
        const filters = this.lastSearchedFilters
        filters.contact = occurence.contact
        filters.account = occurence.account
        this.$router.push({path:`accounts/${occurence.account.number}`, query: { meta: { view: "conversation", filters: filters }}})
      }
    },

    created(){
      this.getAccounts()
    }
  };

</script>


<style scoped>
.filterResult{
  min-height: 300px;
}
</style>
  