<template>
    <v-sheet elevation="4">
        <div v-if="results.length > 0" class="px-5 pt-2">
            <div v-for="(result,index) in results" :key="index">
                <div class="occurence">
                <div>Match found between <b>{{ result.account.name }} ({{ result.account.cmsurn }})</b> and <b>{{getContactName(result)}} with</b></div>
                <div class="occurenceLink" @click="conversationClicked(result)"><b><a>{{`${result.occurences} occurences`}}</a></b></div>
                <router-link active-class="active" to="{name: 'AccountDetails' " class="ml-2" ></router-link>
                </div>
            </div>
        </div>
        <div class="text-center pt-10" v-else>
            No results matched your search
        </div>
    </v-sheet>

</template>
  
<script>

  export default {

    props:{
      results: Array
    },  
  
    data: () => ({
        getContactName(result){
            const contactName = `${result.contact.forenames} ${result.contact.surname} (${result.contact.digits})`.trim()
            return contactName
        },  
        getResultText(result){
            const contactName = `${result.contact.forenames} ${result.contact.surname}`.trim()
            var text = `Search matched between ${result.account.name} and ${contactName} with`
            return text
        },

        conversationClicked(conversation){
            this.$emit("occurenceSelected", conversation)

        }
    }),
  };

</script>


<style scoped>
div#transcript-timestamp{
  opacity: 0.5;
}
div#transcript-message:hover div#transcript-timestamp{
  opacity: 1;
}

.occurenceLink{
    margin-left: 5px;
}

.occurenceLink:hover{
    margin-left: 6px;
    text-decoration: underline;
}

.occurence{
    vertical-align: top;
    display: flex;
    padding-top: 15px;
}

</style>
  