import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        primary: '#3094cc',
        secondary: '#1565C0'
      }
})

export default vuetify
