export default class StatisticRoutes{
    constructor(axios){
        this.axiosInstance = axios
    }

    getDashboardStatistics(){
        const url = ""
        return new Promise((resolve,reject) => {
            this.axiosInstance.get(url).then(response => {
                const statistics = response.data
                resolve(statistics)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    getAllActiveUsers(){

    }

    getActiveAccounts(){

    }

    getActiveContacts(){

    }


}