<template>
    <v-container fluid>

        <v-row class="pl-10 pr-10 ">
          <h2 @click="deselectAccount" class="pointer underLineOnHover">Accounts</h2>
            <h2 class="pl-2 pr-2" v-if="selectedAccount != null"> / </h2>
            <h2 v-if="selectedAccount != null">{{ selectedAccount.name }}</h2>
    
        </v-row>

        <v-row class=" pt-5 pl-10 pr-10" no-gutters>
          <v-col md="12">
            
            <AccountsTable :data="accounts" v-on:accountSelected = "selectAccount"/>

        
            </v-col>

        </v-row>

    </v-container>

</template>

  
<script>

  import AccountsTable from '@/views/Accounts/AccountsTable'
  
  export default {

    components:{
      AccountsTable,
    },
  
    data: () => ({
      accounts: [],
      selectedAccount: null,


    }),

    computed:{


    },

    methods:{
      async getAccounts(){
        try{
          const accounts = await this.$utapService.accounts.getAllAccounts(this.$store.getters.getSiteId)
          this.accounts = accounts
        }
        catch(error){
          console.log("Error fetching accounts: " + error)
        }
      },

      selectAccount(account){
        this.selectedAccount = account 
        this.$router.push({path:`accounts/${account.number}`})
      },
      

      deselectAccount(){
        this.selectedAccount = null
        this.primaryPageComponent = AccountsTable
      }
    },

    async created(){
      await this.getAccounts()
    }
  };

</script>


<style scoped>
.pointer{
  cursor: pointer;
}

.underLineOnHover:hover{
  text-decoration: underline;
}

</style>
  