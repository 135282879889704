import Vue from 'vue'

import Router from "vue-router";
import Dashboard from '@/views/Dashboard'

import AccountDetails from "@/views/AccountDetails"
import Accounts from '@/views/Accounts'
import Numbers from '@/views/Numbers'
import Messages from '@/views/Messages'
import Sites from "@/views/Sites"
import RouterView from "@/views/RouterView.vue"
import store from './store';


Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes:[
		{name: "Sites", path: "/", component: Sites, meta: {requiresAuth: true}},
		{
			name: "Home",
			path:'/:site',
			redirect: "/:site/dashboard",
			component: RouterView,
			props:true,
			meta:{requiresAuth: true},

			children:[
				{name: "Dashboard", path: "dashboard", component: Dashboard},
				{name: "Accounts", path: "accounts", component: Accounts},
				{name: "AccountDetails", path: "accounts/:accountId", component: AccountDetails, props:true},
				{name: "Numbers", path: "numbers", component: Numbers},
				{name: "Messages", path: "messages", component: Messages}
			]
		}
	]
})

router.beforeEach(async (to,from,next) => {
	if(to.name != "Sites" && to.path != "/oidc/return"){
		if(!to.params.site){
			next({ name: "Sites", replace: true})
		}
	}
	next()
})

export default router
