export default class MessageRoutes{

    constructor(axios){
        this.axiosInstance = axios
    }

    getConversation(pin,digits, filterParams){
        return new Promise((resolve,reject) => {
            const url = `messages/pin/${pin}/digits/${digits}`
            this.axiosInstance.get(url, {params: filterParams}).then(response => {
                const messages = response.data
                resolve(messages) 
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    getConversationOccurences(site,filterParams){
        return new Promise((resolve,reject) => {
            const url = `messages/${site}/occurences`
            this.axiosInstance.get(url, {params: filterParams}).then(response => {
                const occurences = response.data
                resolve(occurences)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

}