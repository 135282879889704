export default class AccountRoutes{

    constructor(axios){
        this.axiosInstance = axios
    }

    getAllAccounts(site){
        const url = `PINs/all/${site}`
        return new Promise((resolve,reject) => {
            this.axiosInstance.get(url).then(response => {
                const accounts = response.data
                resolve(accounts)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    getAccount(id){
        const url = `PINs/${id}`
        return new Promise((resolve,reject) => {
            this.axiosInstance.get(url).then(response => {
                const account = response.data
                resolve(account)
            })
            .catc(error => {
                reject(error)
            })
        })
    }

    getAccountProfile(site,id){
        const url = `PINs/${id}/site/${site}/profile`
        return new Promise((resolve,reject) => {
            this.axiosInstance.get(url).then(response => {
                const profile = response.data
                resolve(profile)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    getAccountContacts(site,pin){
        const url = `PINs/${pin}/site/${site}/contacts`
        return new Promise((resolve,reject) => {
            this.axiosInstance.get(url).then(response => {
                const contacts = response.data
                resolve(contacts)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    getAllContacts(site){
        const url = `PINs/contacts/${site}`
        return new Promise((resolve,reject) => {
            this.axiosInstance.get(url).then(response => {
                const contacts = response.data
                resolve(contacts)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    getAccountCredit(){
        const url = ""
        return new Promise((resolve,reject) => {
            this.axiosInstance.get(url).then(response => {
                const credit = response.data
                resolve(credit)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    createSuspension(suspension){
        const url = "suspension"
        return new Promise((resolve,reject) => {
            this.axiosInstance.post(url,suspension).then(response => {
                const success = response.data
                resolve(success)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    removeSuspension(cmsurn){
        const url = `suspension/${cmsurn}`
        return new Promise((resolve,reject) => {
            this.axiosInstance.delete(url).then(response => {
                const success = response.data
                resolve(success)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    purchaseMessages(site,pin,quantity){
        const url = `PINs/${pin}/site/${site}/buyTexts/${quantity}`
        return new Promise((resolve,reject) => {
            this.axiosInstance.post(url).then(response => {
                const success = response.data
                resolve(success)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    refundMessages(site,pin,quantity){
        const url = `PINs/${pin}/site/${site}/refundTexts/${quantity}`
        return new Promise((resolve,reject) => {
            this.axiosInstance.post(url).then(response => {
                const success = response.data
                resolve(success)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

 

}