const utapApi = {
    host: 'api.messaging.unify.fun',
    port: "",
    ssl: true,
}

const mongoConfig = {
    baseUrl: "https://charts.mongodb.com/charts-project-0-lpoeo",
    dashboardId: "6363c628-fb36-4475-86b5-8ea22678aae6"
}

const config = {
    utapApi,
    mongoConfig
}

export default config

