import { createAuth } from '../plugins/oidc'

const config = {
    authority: 'https://whodis.unify.ws',
    client_id: 'messaging-portal-staging',
    scope: 'openid profile messaging-api-staging slumlord-api',
    redirect_uri: `${location.protocol}//${location.host}/oidc/return`,
    silent_redirect_uri:  `${location.protocol}//${location.host}/oidc/renew`,
    post_logout_redirect_uri: `${location.protocol}//${location.host}`,
    response_type: 'code'
}

const auth = createAuth(config)

export default auth
