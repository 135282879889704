import  config  from "../../../config";
import axios from 'axios';
import AccountRoutes from "./routes/AccountRoutes";
import MessageRoutes from "./routes/MessageRoutes";
import StatisticRoutes from "./routes/StatisticRoutes";
import TenantRoutes from "./routes/TenantRoutes";

const utapApi = config.utapApi

const utapAPIUrl = (utapApi.ssl ? 'https' : 'http') + '://' + utapApi.host + ((utapApi.ssl && utapApi.port == '443') || (!utapApi.ssl && utapApi.port == '80') || !utapApi.port ? '' : ':' + utapApi.port) 

const axiosInstance = axios.create({ baseURL: utapAPIUrl })
const accounts = new AccountRoutes(axiosInstance)
const messages = new MessageRoutes(axiosInstance)
const statistics = new StatisticRoutes(axiosInstance)
const tenants = new TenantRoutes(axiosInstance)


function useAuth(auth){

    axiosInstance.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${auth.accessToken}`
        return config
    })

    axiosInstance.interceptors.response.use(response => {
        return Promise.resolve(response)
    },
    error => {
        if(error.response){
            const { status, data } = error.response
            if(status === 403){
                auth.signOut()
            }
        }
        return Promise.reject(error)
    })

}

function useToken(token){
    axiosInstance.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`
        return config
    }) 
}

const apiService = {
    useAuth,
    useToken,
    accounts,
    messages,
    tenants,
    statistics
}

export default apiService
