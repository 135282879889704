<template>
    <v-container>

        <v-navigation-drawer permanent app clipped>
            <v-list dense nav>
                <v-list-item
                v-for="item in navigationRoutes" :key="item.title" link :to="{name: item.name} ">
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <router-view>

        </router-view>

    </v-container>
    
</template>

<script>
export default {

    computed:{
        currentRouteName(){
            return this.$route.name
        }
    },

    data: () => ({

        navigationRoutes: [
    {name: "Dashboard", path:"/dashboard", icon: 'mdi-view-dashboard'},
    {name: "Accounts", path: "/accounts", icon: 'mdi-account-multiple'},
    {name: "Numbers", path: "/numbers", icon: 'mdi-dialpad'},
    {name: "Messages", path: "/messages", icon: 'mdi-message'}],
        
    }),
}
</script>