<template>
    <div>

      <div ref="transcriptContainer" v-for="message in messages" :key="message.messageId" >
        <v-row id="transcript-message">
          <v-col  md="8">
            <span :id="message.messageId"><b>{{ message.fromInside ? "Prisoner" : "Contact" }}:</b> {{message.text}}</span>
          </v-col>
          <v-col  md="2">
          </v-col>
          <v-spacer/>
          <v-col class="pl-10" id="transcript-timestamp" md="2">{{ formatTime(message.timestamp) }}</v-col>
        </v-row>
      </div>

    </div>

</template>
  
<script>

  export default {

    props:{
      messages: Array
    },  
  
    data: () => ({
      messagesHighlighted : []
      //
    }),
    computed:{
      
    },  

    methods:{
      clearHighlights(){
        this.messagesHighlighted.forEach(message => {
          this.highlightMessage(message, "#FFFFFF")
        })
      },  
      highlightMessages(messages){
        if(this.messagesHighlighted.length > 0) this.clearHighlights()
        messages.forEach(message => {
          this.highlightMessage(message, "#FFFF00")
        })
      },
      highlightMessage(message, color){
        var element = document.getElementById(message.messageId)
        element.style.backgroundColor = color
        this.messagesHighlighted.push(message)
      },
      scrollToMessage(message){
        var element = document.getElementById(message.messageId)
        element.scrollIntoView()
      },


      senderNameToDisplay(message){
        if(message.fromInside){
          return message.PAN
        }
        else{
          return message.PIN
        }
      }

    }
  };

</script>


<style scoped>
div#transcript-timestamp{
  opacity: 0.5;
}
div#transcript-message:hover div#transcript-timestamp{
  opacity: 1;
}

</style>
  