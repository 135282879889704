export default class TenantRoutes{

    constructor(axios){
        this.axiosInstance = axios
    }


    get(){
        return new Promise((resolve,reject) => {
            const url = `tenancy`
            this.axiosInstance.get(url).then(response => {
                const tenants = response.data
                resolve(tenants)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    getOne(tenantId){
        return new Promise((resolve,reject) => {
            const url = `tenancy/${tenantId}`
            this.axiosInstance.get(url).then(response => {
                const tenant = response.data
                resolve(tenant)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    getSite(siteId){
        return new Promise((resolve,reject) => {
            const url = `tenancy/site/${siteId}`
            this.axiosInstance.get(url).then(response => {
                const site = response.data
                resolve(site)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    getSiteMessagePrice(siteId){
        return new Promise((resolve,reject) => {
            const url = `tenancy/site/${siteId}/messagePrice`
            this.axiosInstance.get(url).then(response => {
                const messagePrice = response.data
                resolve(messagePrice)
            })
            .catch(error => {
                reject(error)
            })
        })
    }


}