import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state:{
        site: {}
    },
    mutations:{
        setSite(state, site){
            state.site = site
        }
    },

    getters:{
        getSiteId: state => state.site.key
    }
})