<template>
    
    <v-card elevation="4" >
        <v-container  style="background-color: transparent;">
        <v-row>

            <v-col md="3">
                   <v-select 
                    :items="contacts"
                    v-model="selectedContact"
                    item-text="forenames"
                    label="Contact"
                    return-object
                    solo ></v-select> 
            </v-col>

            <v-col md="2">
                <v-menu
                v-model="fromDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="computedFromDateFormatted"
                            label="From date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="fromDate"
                    @input="fromDateMenu = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>    
            <v-col md="2">
                <v-menu
                v-model="toDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="computedToDateFormatted"
                            label="To date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="toDate"
                    @input="toDateMenu = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>  
            <v-col md="3">
                <v-text-field solo label="Word/Phrase" v-model="wordPhrase"></v-text-field> 
            </v-col>  
            
            <v-col md="2" class="pt-5">
                <v-spacer/>
                <v-btn @click="clearFilter()">Reset</v-btn>
                <v-btn :disabled="disableSearch" class="ml-5" @click="searchFilter()" color="primary">Search</v-btn>
            </v-col>

        </v-row>

  

    </v-container>

    <v-card-actions>
     
    </v-card-actions>

    </v-card>
    
</template>

<script>
export default {

    props:{
        contacts: Array
    },


    data(){
        return{

            fromDateMenu: false,
            fromDate: null,

            toDateMenu: false,
            toDate: null,

            selectedContact: null,
            wordPhrase: null,

            disableSearch: false
        }
    },

    computed:{
        computedFromDateFormatted () {
            return this.fromDate ? this.moment(this.fromDate).format('dddd, MMMM Do YYYY') : ''
        },

        computedToDateFormatted () {
            return this.toDate ? this.moment(this.toDate).format('dddd, MMMM Do YYYY') : ''
        },
    },  

    methods:{

        clearFilter(){
            this.fromDateMenu = false
            this.toDateMenu = false
            this.fromDate = null
            this.toDate = null
            this.wordPhrase = ""
            this.selectedContact = null
            this.$emit("filtersCleared")
        },

        searchFilter(){
            if(this.selectedContact != null){
                var searchFilters = {contact: this.selectedContact, fromDate: this.fromDate, toDate: this.toDate, 
                    wordPhrase: this.wordPhrase ? this.wordPhrase.trim() : "" }
                this.$emit("filterConfirmed", searchFilters)
            }
        },

        getFilters(){
            var searchFilters = {contact: this.selectedContact, fromDate: this.fromDate, toDate: this.toDate, 
                wordPhrase: this.wordPhrase ? this.wordPhrase.trim() : ""}
            return searchFilters
        },

        disableSearchButton(disable){
            this.disableSearch = disable
        },

        setFilters(filters){
            this.selectedContact = filters.contact
            this.fromDate = filters.fromDate
            this.toDate = filters.toDate
            this.wordPhrase = filters.wordPhrase
        },
        
    },
}
</script>