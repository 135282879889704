<template>
    <v-container>
      <v-row no-gutters>
   
        <span>Profile</span>
        <v-spacer/>
        <span class="pr-2">Account status:</span>
        <div>
          <div v-if="account.suspended" style="background-color:red; border-radius: 40px; color: white;" class="px-2">Suspended</div>
          <div v-else style="background-color:green; border-radius: 40px; color: white;" class="px-2">Active</div>
          
        </div>
        
      </v-row>
      
      <v-row>
        <v-col md="12">
          <v-card >

            <div v-if="account != null" class="px-10 pt-5 pb-5">

              <div class="text-subtitle-1">Sentence</div>
              <div class="text-h6 text--primary mb-4">
                {{ account.sentence }}
              </div>

              <div class="text-subtitle-1">PIN</div>
              <div class="text-h6 text--primary mb-4">
                {{ account.pinNumber }}
              </div>

              <div class="text-subtitle-1">Credit balance</div>
              <div class="text-h6 text--primary mb-4">
                £{{ account.credit.balance }}
              </div>

   
              <div class="text-subtitle-1">Purchased messages</div>
              <div class="text-h6 text--primary mb-4">
                {{ account.credit.boughtMessages }}
              </div>
      
              <div class="text-subtitle-1">Free messages</div>
              <div class="text-h6 text--primary mb-4">
                {{ account.credit.freeTexts }}
              </div>
              
              

          </div>

              
          <v-card-actions>
            <v-btn class="px-5" color="primary" style="color:white" @click="showBuyMessagesDialog">
                Buy messages 
            </v-btn>
            <v-btn class="px-5" color="primary" style="color:white" @click="showRefundMessagesDialog">
                Refund messages 
            </v-btn>
            <v-spacer></v-spacer>
              <v-btn v-if="!account.suspended" class="px-5" color="red" style="color:white" @click="showSuspensionDialog">
                Suspend account
              </v-btn>
              <v-btn v-else class="px-5" color="green" style="color:white" @click="showRemoveSuspensionDialog">
                Revoke suspension 
              </v-btn>
          </v-card-actions>

  
          
          </v-card>

        </v-col>

      </v-row>

      <SuspensionDialog ref="suspensionDialog" v-on:suspensionConfirmed="confirmSuspension"/>
      <ConfirmationDialog ref="confirmationDialog" title="Revoke suspension" subtitle="Are you sure you want to revoke this account suspension?" v-on:confirmed="removeSuspension"/>
      <BuyTextsDialog :pricePerMessage="messagePrice" ref="buyTextsDialog" v-on:messagesPurchased="purchaseMessages" />
      <RefundTextsDialog :pricePerMessage="messagePrice" ref="refundTextsDialog" v-on:messagesRefunded="refundMessages"/>
    </v-container>

</template>
  
<script>

import SuspensionDialog from "@/components/Dialogs/SuspensionDialog.vue"
import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog.vue"
import BuyTextsDialog from "@/components/Dialogs/BuyTextsDialog.vue"
import RefundTextsDialog from "@/components/Dialogs/RefundTextsDialog.vue"

  
  export default {

    props:{
      account: Object
    },  

    components:{
      SuspensionDialog,
      ConfirmationDialog,
      BuyTextsDialog,
      RefundTextsDialog
    },
  
    data: () => ({
      messagePrice: null
      //
    }),

    created(){
      this.$utapService.tenants.getSiteMessagePrice(this.$store.getters.getSiteId).then(messagePrice => {
        this.messagePrice = messagePrice
      })
    },    

    methods:{
      showSuspensionDialog(){
        this.$refs.suspensionDialog.open()
      },
      showRemoveSuspensionDialog(){
        this.$refs.confirmationDialog.open()
      },  
      showBuyMessagesDialog(){
        this.$refs.buyTextsDialog.open()
      },
      showRefundMessagesDialog(){
        this.$refs.refundTextsDialog.open()
      },

      async purchaseMessages(quantity){

        try{
          const success = await this.$utapService.accounts.purchaseMessages(this.$store.getters.getSiteId,this.account.pinNumber,quantity)
          if(success){
            this.account.credit.boughtMessages += quantity
            this.$emit("creditUpdated")
          }
        }
        catch{
          console.log("Error purchasing messages:")
        }

      },
      async refundMessages(quantity){
        try{
          const success = await this.$utapService.accounts.refundMessages(this.$store.getters.getSiteId,this.account.pinNumber,quantity)
          if(success){
            this.account.credit.boughtMessages -= quantity
            this.$emit("creditUpdated")
          }
        }
        catch{
          console.log("Error refunding messages:")
        }

      },


      removeSuspension(){
        this.$utapService.accounts.removeSuspension(this.account.sentence).then(success => {
          if(success){
            this.account.suspended = false
          }
        })
      },

      confirmSuspension(reason){
        const data = {sentence: this.account.sentence, reason: reason}
        this.$utapService.accounts.createSuspension(data).then(success => {
          if(success){
            this.account.suspended = true
          }
        })
      }
    }
  }

</script>


<style scoped>
.headerAndValue{ 
  display:inline-block; 
  padding:0px; 
  margin-left:-4px; 
} 

</style>
  