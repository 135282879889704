import config from "../../../config";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom"

const mongoConfig = config.mongoConfig

const sdk = new ChartsEmbedSDK({
    baseUrl: mongoConfig.baseUrl
})

async function renderDashboard(dashboardId, renderTo){
    const dashboard = sdk.createDashboard({dashboardId: dashboardId, showAttribution: false})
    const dashboardElement = document.getElementById(renderTo)
    await dashboard.render(dashboardElement)
}

async function getChart(chartId){
    const chart = sdk.createChart({ chartId: chartId}).render().then(c => {
        return chart
    })
    .catch(error => {
        console.log("Error rendering chart: " + error)
    }
    )   
}

const mongoService = {
    renderDashboard,
    getChart,
}

export default mongoService
