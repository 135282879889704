<template>
    <v-container fluid>

      <v-row class="pl-10 pr-10">
          <h2 >Numbers</h2>
        </v-row>

        <v-row class=" pt-5 pl-10 pr-10" no-gutters>
          <v-col md="12">

                <NumbersTable :data="numbers"/>
            </v-col>

        </v-row>

    </v-container>

</template>

  
<script>

import NumbersTable from "@/views/Numbers/NumbersTable"

  
  export default {

    components:{
        NumbersTable
    },
  
    data: () => ({
      numbers: [],


    }),

    computed:{
     
    },

    methods:{
      getContacts(){
        this.$utapService.accounts.getAllContacts(this.$store.getters.getSiteId).then(contacts => {
            this.numbers = contacts
        })
        .catch(error => {
          console.log("Error fetching accounts: " + error)
        })
      },

      selectContact(contact){

      }
    },

    created(){
      this.getContacts()
    }
  };

</script>


<style scoped>
h2{
  font-weight: lighter;
}

</style>
  