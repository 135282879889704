<template>
     <v-sheet elevation="8">

        <v-row no-gutters>
            <v-spacer/>
            <v-col md="3">
                 <v-text-field class="pr-5" v-model="filterSearch" prepend-icon="mdi-magnify" label="Search" single-line clearable></v-text-field>
            </v-col>
        </v-row>

        
      
        <v-row no-gutters>
            <v-col md="12">
            <v-data-table :headers="headers" :items="data" item-key="cmsurn" disable-sort height="65vh" :search="filterSearch">

<template v-slot:item="row">
    <tr @click="accountClicked(row.item)">
        <td style="width:25%; text-align:center">{{row.item.name}}</td>
        <td style="width:25%; text-align:center">{{row.item.cmsurn}}</td>
        <td style="width:25%; text-align:center">{{row.item.number}}</td>
        <td style="width:25%; text-align:center">{{ row.item.suspended ? "Suspended" : "Active" }}</td>
        <!-- <td style="width:10%">
        <v-btn class="mx-2" small icon @click="editUser(row.item.userID)">
            <v-icon dark>mdi-account-edit</v-icon>
        </v-btn>
        <v-btn class="mx-2" small icon @click="deleteUser(row.item.userID)">
            <v-icon dark>mdi-delete</v-icon>
        </v-btn>
        </td> -->
    </tr>

</template>

</v-data-table>
</v-col>
        </v-row>
   

</v-sheet>

</template>
  
<script>
  
  export default {

    props:{ 
        data: Array,
    },
  
    data: () => ({

        filterSearch: null,

        headers:[
            {
                text: 'Name',
                align: 'center',
                sortable: false,
                value: 'name'
            },
            {
                text: 'Sentence',
                align: 'center',
                sortable: false,
                value: 'cmsurn'
            },
            {
                text: 'Pin',
                align: 'center',
                sortable: false,
                value: 'number'
            },
            {
                text: 'Account Status',
                align: 'center',
                sortable: false,
                value: 'suspended'
            }
        ]
      //
    }),
    methods:{
        accountClicked(account){
            this.$emit("accountSelected", account)
        }
    },  
    mounted(){
    }
  };

</script>
  