<template>
    <v-dialog v-model="visible" max-width="400px">
        <v-card>
            <v-card-title>Suspend account</v-card-title>
            <v-card-subtitle></v-card-subtitle>

            <v-text-field class="px-10" label="Reason" v-model="reason">

            </v-text-field>
            <v-card-actions>
                <v-spacer/>
                <v-btn>
                    Cancel
                </v-btn>
                <v-btn @click="confirmSuspension" color="red">
                    Suspend
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
    
</template>

<script>
export default {

    data: () => ({
        visible: false,
        reason: undefined
    }),
    
    methods:{

        open(){
            this.visible = true
        },  
        close(){
            this.visible = false
        },

        confirmSuspension(){
            this.$emit("suspensionConfirmed", this.reason)
            this.close()
        }
    }
}
</script>