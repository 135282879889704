<template>

  <div id="dashboard" class="h-full grow">
      
  </div>
    <!-- <v-container class="dashboard-container">

      

        <v-row class="px-10">
          <v-col md="4">
            <StatCard color="blue" title="Active users this week" value="120"></StatCard>
          </v-col>
          <v-col md="4">
            <StatCard color="blue" title="Active users this week" value="120"></StatCard>
          </v-col>
          <v-col md="4">
            <StatCard color="blue" title="Active users this week" value="120"></StatCard>
          </v-col>
        </v-row>

        <v-row class="px-10">

          <v-col md="12">
            <LineGraph line-color="#11deed" title="Messages today"></LineGraph>
          </v-col>

        </v-row>

    </v-container> -->

</template>
  
<script>

//To set up mongoDB charts
//https://www.mongodb.com/developer/products/atlas/charts-javascript-sdk/

import mongoService from '@/services/MongoCharts/mongoService';
import config from "@/../config"
  
  export default {
    components:{
     // StatCard,
     // LineGraph
    },

    data: () => ({

      //
    }),
    mounted(){
     // console.log("Dashboard ID: " + JSON.stringify(config))
      mongoService.renderDashboard(config.mongoConfig.dashboardId,"dashboard")
    }
  };

</script>


<style scoped>
  .dashboard-container{
 
  }
  #dashboard{
    height: 90vh;
  }
</style>
  